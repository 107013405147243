import React, { useState } from "react";
import productStyle from '../ProductCard/Product.module.css'
import ImageDialog from "../AppDialogs/ImageDialog";
import { productData } from "../../Data/ProductData";
import { Link, useNavigate } from "react-router-dom";



const Product = () => {

  const [open, setOpen] = useState(false)
  const [imgSrc, setImgSrc] = useState('')
  const [imageTitle, setImageTitle] = useState('')


  const handleShowDialog = (imgSrc, title) => {
    setOpen(true);
    setImgSrc(imgSrc)
    setImageTitle(title)
  };

  const handleCloseDialog = () => {
    setOpen(!open)
    setImgSrc('')
    setImageTitle('')
  }

  return (
    <div className={productStyle.title} >
      <h5>Products</h5>
      <div className={productStyle.container}>
        <div className={productStyle.cards}>
          {productData.map((product, i) => {
            if (i < 8)
              return (
                <div className={productStyle.card} key={i}>
                  <img className={productStyle.card_image} src={product.image}
                    height='100px' alt="Avatar" onClick={() => { handleShowDialog(product.image, product.productName) }} />
                  <div className={productStyle.card_container}>
                    <span className={productStyle.card_title}>{product.productName}</span>
                    <br />
                    <span className={productStyle.price}>Rs.{product.price}</span>
                    <p className={productStyle.description}>{product.description}</p>
                  </div>
                  <div className={productStyle.action}>
                        <div className={productStyle.action1}>Buy Now</div>
                        <div className={productStyle.action2} >
                          <a href={"/products/view/"+product.id}>View Product</a>
                        </div>
                  </div>
                </div>
              )
          })}
        </div>
        <div className={productStyle.view_more}>
          <a href="/products"> View More Products &#8594;</a>
        </div>
      </div>
      <ImageDialog src={imgSrc} open={open} handleClose={handleCloseDialog} title={imageTitle} />
    </div>
  );
};

export default Product;
