import React from "react";
import treatmentStyle from "../Manufacturing/Manufacture.module.css";
import DoctorImage from "../../images/doctor_image.png";
import FeverImage from "../../images/fever.png";
import StomackDiagnosis from "../../images/stomack_diagnosis.png";
import PinkBero from "../../images/pink_bero.png"
import VoiletBero from "../../images/voilet_bero.png"
import WoodBero from "../../images/wood_bero.png"
import SmallBero from "../../images/small_bero.png"
import Shoppa from "../../images/Shopa.jpg"
import Lockers from "../../images/inside_lockers.jpg"

const Treatments = () => {
  const treatments = [
    {
      image: VoiletBero,
      name: "Bero",
      description:
        "The manner in which someone behaves towards or deals with someone or something.",
    },
    {
      image: Lockers,
      name: "Lockers",
      description:
        "The manner in which someone behaves towards or deals with someone or something.",
    },
    {
      image: WoodBero,
      name: "Wood Bero",
      description:
        "The manner in which someone behaves towards or deals with someone or something.",
    },
    {
      image: SmallBero,
      name: "Small Bero",
      description:
        "The manner in which someone behaves towards or deals with someone or something.",
    },
  ];
  return (
    <div className={treatmentStyle.title}>
      <h5>Manufacturing</h5>
      <div className={treatmentStyle.shape}>
        <div className={treatmentStyle.cards}>
          {treatments.map((treatmets, i) => {
            return (
              <div className={treatmentStyle.card} key={i}>
                <div className={treatmentStyle.content}>
                  <div>
                  <img className={treatmentStyle.card_image} src={treatmets.image}/>
                  </div>
                  <div className={treatmentStyle.card_container}>
                    <span className={treatmentStyle.card_title}>
                      {treatmets.name}
                    </span>
                    <p className={treatmentStyle.description}>
                      {treatmets.description}
                    </p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Treatments;
