import { useParams } from "react-router-dom";
import ServiceProductCard from "./ServiceProductCard";
import  productCardStyle  from '../Services/ServiceProduct.module.css';
import ProdcutData, { productData } from "../../Data/ProductData";
import { useEffect } from "react";

const ServiceProducts = () => {
    let { type } = useParams();

    useEffect(() => {
        window.scrollTo(0, 0);
      }, [])

    return (
        <div className={productCardStyle.prodcut_container}>
            {productData.map(data=>(
                  <ServiceProductCard info={data}/>
            ))}
        </div>
    )
}

export default ServiceProducts;