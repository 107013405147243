export const steelProducts = [
    {
        name: "Berow"
    },{
        name: "Cot"
    }, {
        name: "Rack"
    }, {
        name: "Tables"
    }, {
        name: "Stools"
    }, {
        name: "Wardrobe / Cupboard"
    }, {
        name: "Benches"
    }, {
        name: "Chairs"
    }, {
        name: "Book Case"
    }
] 