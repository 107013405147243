import React from "react";
import AboutProfile from "../component/about/AboutProfile";

const About = () =>{
    return (
        <div>
           <AboutProfile/>
        </div>
    )
}

export default About;