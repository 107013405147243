import BeroHeader from '../images/bero_header.png'
import WoodBero from '../images/wood_bero.png'
import PinkBero from "../images/pink_bero.png"
import VoiletBero from "../images/voilet_bero.png"
import SmallBero from "../images/small_bero.png"
import Shoppa from "../images/Shopa.jpg"
import Lockers from "../images/inside_lockers.jpg"


export const galleryImages = [
    {
        "id": 1,
        "src": BeroHeader,
    },
    {
        "id": 2,
        "src": WoodBero,
    }, {
        "id": 3,
        "src": Lockers,
    }, {
        "id": 4,
        "src": PinkBero,
    }, {
        "id": 5,
        "src": VoiletBero,
    }, {
        "id": 6,
        "src": Shoppa,
    }, {

        "id": 7,
        "src": SmallBero,
    }

]