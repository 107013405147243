import viewProductStyle from '../ProductCard/ViewProduct.module.css'
import VoiletBero from '../../images/voilet_bero.png';
import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import ImageDialog from '../AppDialogs/ImageDialog';
import { productData } from "../../Data/ProductData";

const ViewProductInfo = () => {
    let { id } = useParams();
    const [product, setProduct] = useState({
        image: '',
        productName: "",
        modelName: "",
        kg: "",
        height: "",
        length: "",
        width: "",
        colour: "",
        angerHeight: "",
        locker: "",
        secretLocker: "",
        jewelBox: "",
        letterBox: "",
        tieBar: "",
        glassSize: "",
        weight: "",
        draw: "",
        price: 0,
        description: "",
        features: []
    });
    const location = useLocation();
    const [open, setOpen] = useState(false)
    const [imgSrc, setImgSrc] = useState('')
    const [imageTitle, setImageTitle] = useState('')

    useEffect(() => {
        productData.map((prd) => {
            console.log(prd.id, parseInt(id))
            if (prd.id === parseInt(id)) {
                console.log(prd, id)
                setProduct(prd);
            }
        });
    }, []);


    const handleShowDialog = (imgSrc, title) => {
        setOpen(true);
        setImgSrc(imgSrc)
        setImageTitle(title)
    };

    const handleCloseDialog = () => {
        setOpen(!open)
        setImgSrc('')
        setImageTitle('')
    }

    return (
        <div className={viewProductStyle.view_container}>
            <div className={viewProductStyle.product_container}>
                <div className={viewProductStyle.image_container} onClick={() => { handleShowDialog(product.image, product.productName) }}>
                    <img src={product.image} />
                </div>
                <div className={viewProductStyle.info_container}>
                    <span className={viewProductStyle.product_title}>{product.productName}</span><br /><br />
                    <h6>Product Description</h6>
                    <span className={viewProductStyle.product_description}>
                        {product.description}
                    </span>
                    <br /><br />
                    <h6>Product Details</h6>
                    <div className={viewProductStyle.product_details}>
                        <table>
                            <tr>
                                <td style={{ fontWeight: 'bold' }}>Model Name</td>
                                <td>:</td>
                                <td>{product.modelName}</td>
                            </tr>
                            <tr>
                                <td style={{ fontWeight: 'bold' }}>Kilo Gram</td>
                                <td>:</td>
                                <td>{product.kg}</td>
                            </tr>
                            <tr>
                                <td style={{ fontWeight: 'bold' }}>Height</td>
                                <td>:</td>
                                <td>{product.height}</td>
                            </tr>
                            <tr>
                                <td style={{ fontWeight: 'bold' }}>Length</td>
                                <td>:</td>
                                <td>{product.length}</td>
                            </tr>
                            <tr>
                                <td style={{ fontWeight: 'bold' }}>Width</td>
                                <td>:</td>
                                <td>{product.width}</td>
                            </tr>
                            <tr>
                                <td style={{ fontWeight: 'bold' }}>Colour</td>
                                <td>:</td>
                                <td>{product.colour}</td>
                            </tr>
                        </table>
                        <table>
                            <tr>
                                <td style={{ fontWeight: 'bold' }}>Anger Height</td>
                                <td>:</td>
                                <td>{product.angerHeight}</td>
                            </tr>
                            <tr>
                                <td style={{ fontWeight: 'bold' }}>Locker</td>
                                <td>:</td>
                                <td>{product.locker}</td>
                            </tr>
                            <tr>
                                <td style={{ fontWeight: 'bold' }}>Secret Locker</td>
                                <td>:</td>
                                <td>{product.secretLocker}</td>
                            </tr>
                            <tr>
                                <td style={{ fontWeight: 'bold' }}>Jewel Box</td>
                                <td>:</td>
                                <td>{product.jewelBox}</td>
                            </tr>
                            <tr>
                                <td style={{ fontWeight: 'bold' }}>Letter Box</td>
                                <td>:</td>
                                <td>{product.letterBox}</td>
                            </tr>
                            <tr>
                                <td style={{ fontWeight: 'bold' }}>Tie Bar</td>
                                <td>:</td>
                                <td>{product.tieBar}</td>
                            </tr>
                        </table>
                        <table>
                            <tr>
                                <td style={{ fontWeight: 'bold' }}>Glass Size</td>
                                <td>:</td>
                                <td>{product.glassSize}</td>
                            </tr>
                            <tr>
                                <td style={{ fontWeight: 'bold' }}>Weight</td>
                                <td>:</td>
                                <td>{product.weight}</td>
                            </tr>
                            <tr>
                                <td style={{ fontWeight: 'bold' }}>Draw</td>
                                <td>:</td>
                                <td>{product.draw}</td>
                            </tr>
                            <tr>
                                <td style={{ fontWeight: 'bold' }}> </td>
                                <td> </td>
                                <td> </td>
                            </tr>
                            <tr>
                                <td style={{ fontWeight: 'bold' }}> </td>
                                <td> </td>
                                <td></td>
                            </tr>
                            <tr>
                                <td style={{ fontWeight: 'bold' }}> </td>
                                <td> </td>
                                <td> </td>
                            </tr>
                        </table>
                    </div>
                    <div className={viewProductStyle.features_container}>
                        <h6>Product Features</h6>
                        {product.features.map((feature) => {
                            return (
                                <li>{feature}</li>
                            )
                        })}
                    </div>
                    <div className={viewProductStyle.price_info}>
                        <span>Rs.{product.price}</span>
                        <button className={viewProductStyle.contact_button}>Contact Seller</button>
                    </div>

                </div>
            </div>
            <ImageDialog src={imgSrc} open={open} handleClose={handleCloseDialog} title={imageTitle} />
        </div>
    )
}
export default ViewProductInfo;
