import React, { useState } from "react";
import navStyles from "../navbar/NavBar.module.css";
import {
  AiOutlineMenu,
  AiOutlineClose,
  AiOutlineInstagram,
  AiOutlineWhatsApp,
} from "react-icons/ai";
import Logo from "../../images/bero_header.png";
import SiteInfo from '../../Data/SiteInfo.json';

const NavBar = () => {
  const [nav, setNav] = useState(false);
  return (
    <header className={navStyles.navBar}>
      <div className={navStyles.website_title}>
        {/* <img className={navStyles.logo_image_size} src={Logo} alt="/" /> */}
        <h1>VBPL</h1>
      </div>
      <nav>
        <ul className={nav? [navStyles.menu,navStyles.active].join(" "):navStyles.menu}>
          <li>
            <a href="/" >Home</a>
          </li>
          <li>
            <a href="/services">Services</a>
          </li>
          <li>
            <a href="/products">Products</a>
          </li> 
          <li>
            <a href="/gallery">Gallery</a>
          </li>
          <li>
            <a href="/about">About</a>
          </li>
          <li>
            <AiOutlineWhatsApp color='white' size={25} style={{ marginTop: "6px" }} />
          </li>
          <li>
            <AiOutlineInstagram color='white' size={25} style={{ marginTop: "6px" }} />
          </li>
        </ul>
      </nav>
      <div onClick={() => setNav(!nav)} className={navStyles.mobile_btn}>
        {nav ? <AiOutlineClose size={25} /> : <AiOutlineMenu size={25} color="white"/>}
      </div>
    </header>
  );
};

export default NavBar;
