import React from "react";
import footerStyle from '../footer/Footer.module.css'
import SiteInfo from '../../Data/SiteInfo.json'
import {
    AiOutlineInstagram,
    AiOutlineWhatsApp,
    AiFillFacebook,
    AiFillLinkedin
  } from "react-icons/ai";
  



const Footer = () => {
    return (
        <footer class={footerStyle.footer}>
            <ul class={footerStyle.social_icon}>
                <li class={footerStyle.icon__item}><a class={footerStyle.social_icon__link} href="/">
                    <AiOutlineInstagram size={25}/>
                </a></li>
                <li class={footerStyle.icon__item}><a class={footerStyle.social_icon__link} href="/">
                    <AiOutlineWhatsApp size={25}/>
                </a></li>
                <li class={footerStyle.icon__item}><a class={footerStyle.social_icon__link} href="/">
                    <AiFillFacebook size={25}/>
                </a></li>
                <li class={footerStyle.icon__item}><a class={footerStyle.social_icon__link} href="/">
                    <AiFillLinkedin size={25}/>
                </a></li>
            </ul>
            <ul class={footerStyle.menu}>
                <li class={footerStyle.menu__item}><a class={footerStyle.menu__link} href="/">Home</a></li>
                <li class={footerStyle.menu__item}><a class={footerStyle.menu__link} href="/products">Products</a></li>
                <li class={footerStyle.menu__item}><a class={footerStyle.menu__link} href="/gallery">Gallery</a></li>
                <li class={footerStyle.menu__item}><a class={footerStyle.menu__link} href="/about">About</a></li>
            </ul>
            <p>&copy;2023 {SiteInfo.siteTitle} | All Rights Reserved</p>
        </footer>
    )
}  

export default Footer;