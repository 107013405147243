import productCardStyle from '../Services/ServiceProduct.module.css';

const ServiceProductCard = ({ info, ...props }) => {
    return (
        <div className={productCardStyle.card}>
            <img src={info.image} alt="Avatar" />
            <div class={productCardStyle.container}>
                <h6><b>{info.productName}</b></h6>
                <p> {info.description}</p>
            </div>
            <div className={productCardStyle.view_container}>
                <span className={productCardStyle.price}>Rs.{info.price}</span>
                <button className={productCardStyle.view_details_btn}>View Details</button>
            </div>
            <div className={productCardStyle.action}>
                Buy Now
            </div>
        </div>
    )
}
export default ServiceProductCard;