import React from "react";
import headerStyle from "../header/HeaderInfo.module.css";
import DoctorImage from '../../images/doctor_image.png'
import SiteInfo from '../../Data/SiteInfo.json'
import BeroHeader from '../../images/bero_header.png'
const HeaderInfo = () => {
  return (
    <div className={headerStyle.header_info}>
      <div className={headerStyle.user_info}>
        <span>{SiteInfo.siteTitle}</span>
        <h6>{SiteInfo.address}</h6>
      </div>
      <div>
        {/* <img src={BeroHeader} alt="/" className={headerStyle.doctor_image} /> */}
      </div>
    </div>
  );
};

export default HeaderInfo;
