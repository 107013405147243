import BeroImages from '../images/bero.png';
import BeroHeader from '../images/bero_header.png'
import WoodBero from '../images/wood_bero.png'
import PinkBero from "../images/pink_bero.png"
import VoiletBero from "../images/voilet_bero.png"
import SmallBero from "../images/small_bero.png"
import Shoppa from "../images/Shopa.jpg"
import Lockers from "../images/inside_lockers.jpg"


export const productData = [
    {
      'id':1,
      'image': WoodBero,
      "productName": "Normal Head Model",
      'modelName':"500",
      "kg":"18 kg",
      "height":"6.5 ft",
      "length":'3 ft',
      "width":"1.8 ft",
      "colour":"Rose Wood",
      "angerHeight":"3 ft",
      "locker":"Single",
      "secretLocker":"Single",
      "jewelBox":"Single",
      "letterBox":"Single",
      "tieBar":"Single",
      "glassSize":"4*1 ft",
      "weight":"80 kgs",
      "draw":"Single",
      "price":"13,999",
      "features":[" Highly reliable","Extensively used"," Reasonable price"],
      "description": 'Our company has managed to carve a niche in the market for the manufacture of a wide range of Steel Bero Almirah which are used for a wide range of applications and these products offer a high performance to the customers. Moreover we offer this product at market leading price. All the Exterior and Interior sizes are customized to your wish'
    },
    {
      'id':2,
      'image': BeroHeader,
      "productName": "Raja Rani",
      'modelName':"501",
      "kg":"18 kg",
      "height":"6.5 ft",
      "length":'4 ft',
      "width":"1.8 ft",
      "colour":"Nickel grey + Blue",
      "angerHeight":"3 ft",
      "locker":"Double",
      "secretLocker":"Double",
      "jewelBox":"Double",
      "letterBox":"Double",
      "tieBar":"Single",
      "glassSize":"4*1 ft",
      "weight":"90 kgs",
      "draw":"Single",
      "price":"17999",
      "features":[" Highly reliable","Extensively used"," Reasonable price"],
      "description": 'Our company has managed to carve a niche in the market for the manufacture of a wide range of Steel Bero Almirah which are used for a wide range of applications and these products offer a high performance to the customers. Moreover we offer this product at market leading price. All the Exterior and Interior sizes are customized to your wish'
    },
    {
      'id':3,
      'image': PinkBero,
      "productName": "Normal",
      'modelName':"502",
      "kg":"20 kg",
      "height":"6.5 ft",
      "length":'3 ft',
      "width":"1.8 ft",
      "colour":"Nickel grey + Majentha Rose",
      "angerHeight":"No",
      "locker":"Single",
      "secretLocker":"Single",
      "jewelBox":"Single",
      "letterBox":"Single",
      "tieBar":"Single",
      "glassSize":"4*1 ft",
      "weight":"70 kgs",
      "draw":"Single",
      "price":"12,999",
      "features":[" Highly reliable","Extensively used"," Reasonable price"],
      "description": 'Our company has managed to carve a niche in the market for the manufacture of a wide range of Steel Bero Almirah which are used for a wide range of applications and these products offer a high performance to the customers. Moreover we offer this product at market leading price. All the Exterior and Interior sizes are customized to your wish'
    },
    {
      'id':4,
      'image': PinkBero,
      "productName": "Bero 4 ",
      'modelName':"Normal Anger",
      "kg":"18 kg",
      "height":"6.5 ft",
      "length":'3 ft',
      "width":"1.8 ft",
      "colour":"Violet",
      "angerHeight":"3 ft",
      "locker":"Single",
      "secretLocker":"Single",
      "jewelBox":"Single",
      "letterBox":"Single",
      "tieBar":"Single",
      "glassSize":"4*1 ft",
      "weight":"80 kgs",
      "draw":"Single",
      "price":"15,000",
      "features":[" Highly reliable","Extensively used"," Reasonable price"],
      "description": 'Our company has managed to carve a niche in the market for the manufacture of a wide range of Steel Bero Almirah which are used for a wide range of applications and these products offer a high performance to the customers. Moreover we offer this product at market leading price. All the Exterior and Interior sizes are customized to your wish'
    },
    {
      'id':5,
      'image': VoiletBero,
      "productName": "Bero 5 ",
      'modelName':"Normal Anger",
      "kg":"18 kg",
      "height":"6.5 ft",
      "length":'3 ft',
      "width":"1.8 ft",
      "colour":"Violet",
      "angerHeight":"3 ft",
      "locker":"Single",
      "secretLocker":"Single",
      "jewelBox":"Single",
      "letterBox":"Single",
      "tieBar":"Single",
      "glassSize":"4*1 ft",
      "weight":"80 kgs",
      "draw":"Single",
      "price":"15,000",
      "features":[" Highly reliable","Extensively used"," Reasonable price"],
      "description": 'Our company has managed to carve a niche in the market for the manufacture of a wide range of Steel Bero Almirah which are used for a wide range of applications and these products offer a high performance to the customers. Moreover we offer this product at market leading price. All the Exterior and Interior sizes are customized to your wish'
    },
    {
      'id':6,
      'image': SmallBero,
      "productName": "Bero 6 ",
      'modelName':"Normal Anger",
      "kg":"18 kg",
      "height":"6.5 ft",
      "length":'3 ft',
      "width":"1.8 ft",
      "colour":"Violet",
      "angerHeight":"3 ft",
      "locker":"Single",
      "secretLocker":"Single",
      "jewelBox":"Single",
      "letterBox":"Single",
      "tieBar":"Single",
      "glassSize":"4*1 ft",
      "weight":"80 kgs",
      "draw":"Single",
      "price":"15,000",
      "features":[" Highly reliable","Extensively used"," Reasonable price"],
      "description": 'Our company has managed to carve a niche in the market for the manufacture of a wide range of Steel Bero Almirah which are used for a wide range of applications and these products offer a high performance to the customers. Moreover we offer this product at market leading price. All the Exterior and Interior sizes are customized to your wish'
    },
    {
      'id':7,
      'image': VoiletBero,
      "productName": "Bero 7",
      'modelName':"Normal Anger",
      "kg":"18 kg",
      "height":"6.5 ft",
      "length":'3 ft',
      "width":"1.8 ft",
      "colour":"Violet",
      "angerHeight":"3 ft",
      "locker":"Single",
      "secretLocker":"Single",
      "jewelBox":"Single",
      "letterBox":"Single",
      "tieBar":"Single",
      "glassSize":"4*1 ft",
      "weight":"80 kgs",
      "draw":"Single",
      "price":"15000",
      "features":[" Highly reliable","Extensively used"," Reasonable price"],
      "description": 'Our company has managed to carve a niche in the market for the manufacture of a wide range of Steel Bero Almirah which are used for a wide range of applications and these products offer a high performance to the customers. Moreover we offer this product at market leading price. All the Exterior and Interior sizes are customized to your wish'
    },
    {
      'id':8,
      'image': VoiletBero,
      "productName": "Bero 8 ",
      'modelName':"Normal Anger",
      "kg":"18 kg",
      "height":"6.5 ft",
      "length":'3 ft',
      "width":"1.8 ft",
      "colour":"Violet",
      "angerHeight":"3 ft",
      "locker":"Single",
      "secretLocker":"Single",
      "jewelBox":"Single",
      "letterBox":"Single",
      "tieBar":"Single",
      "glassSize":"4*1 ft",
      "weight":"80 kgs",
      "draw":"Single",
      "price":"15,000",
      "features":[" Highly reliable","Extensively used"," Reasonable price"],
      "description": 'Our company has managed to carve a niche in the market for the manufacture of a wide range of Steel Bero Almirah which are used for a wide range of applications and these products offer a high performance to the customers. Moreover we offer this product at market leading price. All the Exterior and Interior sizes are customized to your wish'
    },
    {
      'id':9,
      'image': VoiletBero,
      "productName": "Bero 9 ",
      'modelName':"Normal Anger",
      "kg":"18 kg",
      "height":"6.5 ft",
      "length":'3 ft',
      "width":"1.8 ft",
      "colour":"Violet",
      "angerHeight":"3 ft",
      "locker":"Single",
      "secretLocker":"Single",
      "jewelBox":"Single",
      "letterBox":"Single",
      "tieBar":"Single",
      "glassSize":"4*1 ft",
      "weight":"80 kgs",
      "draw":"Single",
      "price":"15,000",
      "features":[" Highly reliable","Extensively used"," Reasonable price"],
      "description": 'Our company has managed to carve a niche in the market for the manufacture of a wide range of Steel Bero Almirah which are used for a wide range of applications and these products offer a high performance to the customers. Moreover we offer this product at market leading price. All the Exterior and Interior sizes are customized to your wish'
    },
  ];