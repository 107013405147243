import React from "react"
import flowStyle from '../information_flow/InformationFlow.module.css'
import '../information_flow/InformationFlow.module.css'
import NewSticker from '../../images/new_sticker.png'
import SiteInfo from '../../Data/SiteInfo.json'

const InformationFlow = () => {
    return (
        <div className={flowStyle.scroll_container}>
            <div className={flowStyle.scroll_text}>
                {/* <img src={NewSticker} width='30px' height='20px' alt="/"/> */}
                <p>
                   {SiteInfo.siteTitle}   <b>, Address: </b> {SiteInfo.address}
                </p>
            </div>
        </div>
    )
}

export default InformationFlow;