import React from "react";
import Product from "../component/ProductCard/Product";
import HeaderInfo from "../component/header/HeaderInfo";
import InformationFlow from "../component/information_flow/InformationFlow";
import Treatments from "../component/Manufacturing/Manufacture";
import HomeServices from "../component/Services/HomeServices";
import homeStyle from '../pages/Home.module.css'

const Home = () => {
  return (
    <div className={homeStyle.contents_containner}>
      <HeaderInfo />
      <InformationFlow/>
      <div className={homeStyle.who_weAre_containner}>
        <h5>Who we Are</h5>
        <p className={homeStyle.description}> Up until the revolution of industries, fabrics and clothing were
          made in the home by individuals for personal use. Sometimes they
          were also resale on a small scale. The Textile industry was born
          with the invention of the flying shuttle in 1733, the spinning jenny
          in 1764, and the power loom in 1784. Then the fabrics and clothing
          began to be mass-produced. When James Watt’s improved steam engine
          in 1775, Eli Whitney’s cotton gin in 1792, and Elias Howe’s sewing
          machine in 1846 all contributed greatly to the success of the
          textile industry as well. Now a day, the textile industry is a
          global phenomenon comprised of every business involved in the
          developing, producing, manufacturing, and distribution of textiles.
          Now it is also a very complex industry.</p>
      </div>
      <HomeServices/>
      <Product/>
      <Treatments/>
    </div>
  );
};

export default Home;
