import serviceStyle from '../Services/HomeServices.module.css'
import { steelProducts } from "../../Data/ProductType";
import { useNavigate } from 'react-router-dom';

const Services = () => {
    const navigate = useNavigate();
    const viewServiceProducts = (productType) => {
        navigate("/services/product/" + productType)
    }
    return (
        <div className={serviceStyle.service_container}>
            <div className={serviceStyle.info_cards}>
                <div className={serviceStyle.card}>
                    <img src="https://www.transparentpng.com/thumb/light/a3XKe6-ankitcreation-picsart-and-photoshop-editing-efeects.png"
                        alt="ankitcreation picsart and photoshop editing efeects @transparentpng.com" height="50px"
                        width="50px" />
                    <span className={serviceStyle.card_title}>Steel Products</span>
                </div>
                <div className={serviceStyle.card}>
                    <img src="https://www.transparentpng.com/thumb/light/a3XKe6-ankitcreation-picsart-and-photoshop-editing-efeects.png"
                        alt="ankitcreation picsart and photoshop editing efeects @transparentpng.com" height="50px"
                        width="50px" />
                    <span className={serviceStyle.card_title}>Wooden Products</span>
                </div>
                <div className={serviceStyle.card}>
                    <img src="https://www.transparentpng.com/thumb/light/a3XKe6-ankitcreation-picsart-and-photoshop-editing-efeects.png"
                        alt="ankitcreation picsart and photoshop editing efeects @transparentpng.com" height="50px"
                        width="50px" />
                    <span className={serviceStyle.card_title}>Mattress</span>
                </div>
            </div>
            <img src="https://www.transparentpng.com/thumb/lines/mlfMp9-lines-hd-image.png"
                alt="lines hd image @transparentpng.com" height="10px" width="90%" style={{ textAlign: 'center' }} />
            <div className={serviceStyle.service_content}>
                <div className={serviceStyle.contents}>
                    <span className={serviceStyle.product_content_title}>
                        Steel Products
                    </span>
                    <div className={serviceStyle.product_info_cards}>
                        {steelProducts.map((element) => (
                            <div className={serviceStyle.product_card}>
                                <a href={'/services/product/' + element.name} >
                                    <img src="https://www.transparentpng.com/thumb/light/a3XKe6-ankitcreation-picsart-and-photoshop-editing-efeects.png"
                                        alt="ankitcreation picsart and photoshop editing efeects @transparentpng.com" height="25px"
                                        width="25px" />
                                    <span className={serviceStyle.product_card_title}>{element.name}</span>
                                </a>
                            </div>
                        ))}
                    </div>
                </div>
                <p className={serviceStyle.service_dec}>Consequat laboris nostrud cupidatat labore laboris Lorem exercitation dolor aliquip. Irure
                    in
                    sint excepteur
                    est enim consectetur aliqua. Laborum anim id sunt et ipsum. Est tempor in laboris enim elit. Pariatur
                    sunt
                    elit eu et commodo aliquip enim.
                    Aute magna ex mollit mollit. Elit esse adipisicing aliquip fugiat excepteur aliquip proident occaecat.
                    Pariatur excepteur do dolor sunt eiusmod eu ex laborum. Incididunt fugiat anim occaecat anim officia
                    nostrud
                </p>
            </div>
            <img src="https://www.transparentpng.com/thumb/lens-flare/WDNuCW-transparent-blue-horizontal-lens-flare-blue-line-light-sun-light-bulbs.png"
                alt="@transparentpng.com" height="5px" width="100%" />

            <div className={serviceStyle.service_content}>
                <div className={serviceStyle.contents}>
                    <span className={serviceStyle.product_content_title_mobile}>
                        Wood Products
                    </span>
                    <div className={serviceStyle.product_info_cards}>
                        {steelProducts.map((element) => (
                            <div className={serviceStyle.product_card}>
                                 <a href={'/services/product/' + element.name} >
                                <img src="https://www.transparentpng.com/thumb/light/a3XKe6-ankitcreation-picsart-and-photoshop-editing-efeects.png"
                                    alt="ankitcreation picsart and photoshop editing efeects @transparentpng.com" height="25px"
                                    width="25px" />
                                <span className={serviceStyle.product_card_title}>{element.name}</span>
                                </a>
                            </div>
                        ))}
                    </div>
                    <span className={serviceStyle.product_content_title_not_mobile}>
                        Wood Products
                    </span>
                </div>
                <p className={serviceStyle.service_dec}>Consequat laboris nostrud cupidatat labore laboris Lorem exercitation dolor aliquip. Irure
                    in
                    sint excepteur
                    est enim consectetur aliqua. Laborum anim id sunt et ipsum. Est tempor in laboris enim elit. Pariatur
                    sunt
                    elit eu et commodo aliquip enim.
                    Aute magna ex mollit mollit. Elit esse adipisicing aliquip fugiat excepteur aliquip proident occaecat.
                    Pariatur excepteur do dolor sunt eiusmod eu ex laborum. Incididunt fugiat anim occaecat anim officia
                    nostrud
                </p>
            </div>
            <img src="https://www.transparentpng.com/thumb/lens-flare/WDNuCW-transparent-blue-horizontal-lens-flare-blue-line-light-sun-light-bulbs.png"
                alt="@transparentpng.com" height="5px" width="100%" />

            <div className={serviceStyle.service_content}>
                <div className={serviceStyle.contents}>
                    <span className={serviceStyle.product_content_title}>
                        Mattress
                    </span>
                    <div className={serviceStyle.product_info_cards}>
                        {steelProducts.map((element) => (
                            <div className={serviceStyle.product_card}>
                                  <a href={'/services/product/' + element.name} >
                                <img src="https://www.transparentpng.com/thumb/light/a3XKe6-ankitcreation-picsart-and-photoshop-editing-efeects.png"
                                    alt="ankitcreation picsart and photoshop editing efeects @transparentpng.com" height="25px"
                                    width="25px" />
                                <span className={serviceStyle.product_card_title}>{element.name}</span>
                                </a>
                            </div>

                        ))}
                    </div>
                </div>
                <p className={serviceStyle.service_dec}>Consequat laboris nostrud cupidatat labore laboris Lorem exercitation dolor aliquip. Irure
                    in
                    sint excepteur
                    est enim consectetur aliqua. Laborum anim id sunt et ipsum. Est tempor in laboris enim elit. Pariatur
                    sunt
                    elit eu et commodo aliquip enim.
                    Aute magna ex mollit mollit. Elit esse adipisicing aliquip fugiat excepteur aliquip proident occaecat.
                    Pariatur excepteur do dolor sunt eiusmod eu ex laborum. Incididunt fugiat anim occaecat anim officia
                    nostrud
                </p>
            </div>
            <img src="https://www.transparentpng.com/thumb/lens-flare/WDNuCW-transparent-blue-horizontal-lens-flare-blue-line-light-sun-light-bulbs.png"
                alt="@transparentpng.com" height="5px" width="100%" />
        </div >


    )
}
export default Services;