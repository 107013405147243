import galleryStyle from '../Gallery/GalleryComponent.module.css'
import { galleryImages } from '../../Data/GalleryImages'
import { useState } from 'react'
import ImageDialog from '../AppDialogs/ImageDialog'


const GalleryComponent = () => {
    const [open, setOpen] = useState(false)
    const [imgSrc, setImgSrc] = useState('')
    const [imageTitle, setImageTitle] = useState('')
  
    const handleShowDialog = (imgSrc) => {
      setOpen(true);
      setImgSrc(imgSrc)
    };
  
    const handleCloseDialog = () => {
      setOpen(!open)
      setImgSrc('')
      setImageTitle('')
    }

    return (
        <div className={galleryStyle.imageGalleryWwrapper}>
           {
            galleryImages.map((image,index)=>(
               <div key={index} className={galleryStyle.pics} onClick={()=>handleShowDialog(image.src)}>
                   <img src={image.src} />
                </div>
            ))
           }
        <ImageDialog src={imgSrc} open={open} handleClose={handleCloseDialog} title={''} /> 
        </div>
    )
}

export default GalleryComponent;