import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Footer from './component/footer/Footer';
import NavBar from './component/navbar/NavBar';
import About from './pages/About';
import Home from './pages/Home';
import GalleryComponent from './component/Gallery/GalleryComponent';
import AllProducts from './component/ProductCard/AllProducts';
import ViewProductInfo from './component/ProductCard/ViewProductInfo';
import ServiceProducts from './component/Services/ServiceProduct';
import appStyle from './App.module.css';
function App() {
  return (
    <BrowserRouter>
      <NavBar />
      <div class={appStyle.scroll_to_top}>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/about' element={<About />} />
          <Route path='/gallery' element={<GalleryComponent />} />
          <Route path="/products" element={<AllProducts />} />
          <Route path="/products/view/:id" element={<ViewProductInfo />} />
          <Route path="/services/product/:type" element={<ServiceProducts />} />
        </Routes>
        <div style={{ marginTop: '150px' }}>
          <Footer />
        </div>
      </div>
    </BrowserRouter>
  );
}

export default App;
