import { useState } from 'react'
import productStyle from '../ProductCard/Product.module.css'
import ImageDialog from "../AppDialogs/ImageDialog";
import { productData } from "../../Data/ProductData";
import SiteInfo from "../../Data/SiteInfo.json";
import { useNavigate } from 'react-router-dom';

const AllProducts = () => {
    const navigate = useNavigate();
    const [open, setOpen] = useState(false)
    const [imgSrc, setImgSrc] = useState('')
    const [imageTitle, setImageTitle] = useState('')

    const handleShowDialog = (imgSrc, title) => {
        setOpen(true);
        setImgSrc(imgSrc)
        setImageTitle(title)
    };
    const handleCloseDialog = () => {
        setOpen(!open)
        setImgSrc('')
        setImageTitle('')
    }

    return (
        <div className={productStyle.title} >
            <div className={productStyle.container}>
                <div className={productStyle.cards}>
                    {productData.map((product, i) => {
                        return (
                            <div className={productStyle.card} key={i} onClick={() => { navigate('/products/view', { state: product }) }}>
                                <img className={productStyle.card_image} src={product.image}
                                    height='100px' alt="Avatar" onClick={() => { handleShowDialog(product.image, product.productName) }} />
                                <div className={productStyle.card_container}>
                                    <span className={productStyle.card_title}>{product.productName}</span>
                                    <br />
                                    <span className={productStyle.price}>Rs.{product.price}</span>
                                    <p className={productStyle.description}>{product.description}</p>
                                </div>
                                <div className={productStyle.action}>
                                    <div className={productStyle.action1}>Buy Now</div>
                                    <div className={productStyle.action2} onClick={() => { navigate('/products/view', { state: product }) }}>View Product</div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
            <ImageDialog src={imgSrc} open={open} handleClose={handleCloseDialog} title={imageTitle} />
        </div>
    )
}

export default AllProducts;