import React, { useState } from "react";
import aboutStyle from "../about/AboutProfile.module.css";
import DoctorImage from "../../images/doctor_image.png";
import Shop from '../../images/shop.jpg'
import SiteInfo from '../../Data/SiteInfo.json'
import {
  AiOutlineInstagram,
  AiOutlineWhatsApp,
  AiFillFacebook,
  AiFillLinkedin
} from "react-icons/ai";
import ImageDialog from "../AppDialogs/ImageDialog";

const AboutProfile = () => {
  const [open, setOpen] = useState(false)
  const [imgSrc, setImgSrc] = useState('')

  const handleShowDialog = (imgSrc) => {
    setOpen(true);
    setImgSrc(imgSrc)
  };


  const handleCloseDialog = () => {
    setOpen(!open)
    setImgSrc('')

  }

  return (
    <div className={aboutStyle.main_container}>
      <div className={aboutStyle.profile_container}>
        <img src={Shop} alt="/" onClick={() => handleShowDialog(Shop)} />
        <p className={aboutStyle.p}>
          <h5>About as</h5>
          <span style={{ fontSize: "14px" }}>
            Up until the revolution of industries, fabrics and clothing were
            made in the home by individuals for personal use. Sometimes they
            were also resale on a small scale. The Textile industry was born
            with the invention of the flying shuttle in 1733, the spinning jenny
            in 1764, and the power loom in 1784. Then the fabrics and clothing
            began to be mass-produced. When James Watt’s improved steam engine
            in 1775, Eli Whitney’s cotton gin in 1792, and Elias Howe’s sewing
            machine in 1846 all contributed greatly to the success of the
            textile industry as well. Now a day, the textile industry is a
            global phenomenon comprised of every business involved in the
            developing, producing, manufacturing, and distribution of textiles.
            Now it is also a very complex industry. It starts in agriculture
            with fibre production, husbandry of sheep and silkworm, mining of
            metals and minerals. Then these fibres are processed into yarns,
            fabrics and apparel. This includes spinning mills, weaving mills,
            knitting mills, dyeing mills, garments. In addition, companies that
            sell buttons, zippers, knitting supplies, sewing machines and
            threads, laces, looms, and drapery hardware is also related to this
            industry.
          </span>
        </p>
      </div>
      <div className={aboutStyle.shape1}>
        <span className={aboutStyle.contact_as_title}>Contact as</span>
      </div>
      <div className={aboutStyle.contact_containner}>
        <div className={aboutStyle.contact_card}>
          <span className={aboutStyle.contact_card_title}>{SiteInfo.siteTitle}</span><br />
          <span className={aboutStyle.contact_card_content}>{SiteInfo.address}</span>
        </div>
        <div className={aboutStyle.map_containner}>
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3918.060362214457!2d78.8200887!3d10.8830136!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3baaf1e258e7d1eb%3A0xa4540b688ae170e4!2sBureau%20Point!5e0!3m2!1sen!2sin!4v1695213031463!5m2!1sen!2sin"  loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
      </div>
      <ImageDialog src={imgSrc} open={open} handleClose={handleCloseDialog} title={'About Profile'} />
    </div>
  );
};

export default AboutProfile;
