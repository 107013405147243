import { CloseOutlined } from "@mui/icons-material"
import { Dialog, DialogTitle, IconButton } from "@mui/material"

const ImageDialog = ({src,handleClose,open,title,...props}) => {
    return (
        <Dialog onClose={handleClose} open={open}>
             <DialogTitle sx={{m: 0, p: 2}}>
                {title}
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseOutlined/>
            </IconButton>
        </DialogTitle>
            <div  style={{height:'auto',width:'auto',margin:'20px',textAlign:'center'}}>
            <img src={src} style={{maxHeight:'90vh',maxWidth:'70%'}} />
            </div>
        </Dialog>
    )
}

export default ImageDialog;